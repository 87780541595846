import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["impuestosContainer", "precioGeneral", "valorImpuesto", "contenedorMontos"];

  connect() {
    console.log("ImpuestoController conectado");
    this.checkInitialState();
    this.actualizarDesdePrecioGeneral();

  }

  toggleImpuestos(event) {
    const container = document.getElementById("impuestos-container");
    if (event.target.checked) {
      container.classList.remove("d-none");
    } else {
      container.classList.add("d-none");
    }
  }

  cargarValoresImpuesto(event) {
    const impuestoId = event.target.value;

    if (impuestoId) {
      fetch(`/insumos/cargar_valores_impuesto?impuesto_id=${impuestoId}`, {
        headers: { "Accept": "text/vnd.turbo-stream.html" }
      })
      .then(response => response.text())
      .then(html => Turbo.renderStreamMessage(html));
    }
  }
  checkInitialState() {
    const checkbox = document.querySelector('input[name="cobrar_impuestos"]');
    const container = document.getElementById("impuestos-container");

    if (checkbox.checked) {
      container.classList.remove("d-none");
    }
  }
  actualizarDesdePrecioGeneral() {
    const precioGeneralInput = document.querySelector('input[name="precio_general"]');
    const impuestoSelect = document.getElementById("valor_impuesto_id");
    const precioTotalInput = document.querySelector('input[name="precio_total_con_impuestos"]');
    //const desgloseLabel = document.getElementById("desglose_precios");

    //if (!precioGeneralInput || !impuestoSelect || !precioTotalInput || !desgloseLabel) return;
    if (!precioGeneralInput || !impuestoSelect || !precioTotalInput ) return;

    let precioSinImpuesto = parseFloat(precioGeneralInput.value) || 0;
    let porcentajeImpuesto = parseFloat(impuestoSelect.options[impuestoSelect.selectedIndex]?.text) || 0;

    let impuestoCalculado = precioSinImpuesto * (porcentajeImpuesto / 100);
    let precioTotal = precioSinImpuesto + impuestoCalculado;
      //si es necesario que se muestre la etiqueta de desgloce de iva y precio solo descomentar en todo el controlador esto: desgloseLabel.textContent
    //desgloseLabel.textContent =  `${precioSinImpuesto.toFixed(2)} + ${impuestoCalculado.toFixed(2)} = `;

    precioTotalInput.value = precioTotal.toFixed(2);
  }

  actualizarDesdeTotal() {
    const precioGeneralInput = document.querySelector('input[name="precio_general"]');
    const impuestoSelect = document.getElementById("valor_impuesto_id");
    const precioTotalInput = document.querySelector('input[name="precio_total_con_impuestos"]');
    //const desgloseLabel = document.getElementById("desglose_precios");

    //if (!precioGeneralInput || !impuestoSelect || !precioTotalInput || !desgloseLabel) return;
    if (!precioGeneralInput || !impuestoSelect || !precioTotalInput) return;

    let precioTotal = parseFloat(precioTotalInput.value) || 0;
    let porcentajeImpuesto = parseFloat(impuestoSelect.options[impuestoSelect.selectedIndex]?.text) || 0;

    let precioSinImpuesto = precioTotal / (1 + porcentajeImpuesto / 100);
    let impuestoCalculado = precioTotal - precioSinImpuesto;

    //desgloseLabel.textContent = `${precioSinImpuesto.toFixed(2)} + ${impuestoCalculado.toFixed(2)} = ${precioTotal.toFixed(2)}`;
    //desgloseLabel.textContent = `${precioSinImpuesto.toFixed(2)} + ${impuestoCalculado.toFixed(2)} = `;


    precioGeneralInput.value = precioSinImpuesto.toFixed(2);
  }

  actualizarDesdeImpuesto() {
    this.actualizarDesdePrecioGeneral();
  }
  togglePrecioVariable(event) {
  const precioTotalInput = document.querySelector('input[name="precio_total_con_impuestos"]');
  const precioGeneralInput = document.querySelector('input[name="precio_general"]');
  //const desgloseLabel = document.getElementById("desglose_precios");
  if (!precioTotalInput || !precioGeneralInput) return;
  //if (!precioTotalInput || !precioGeneralInput || !desgloseLabel) return;

  if (event.target.checked) {
    precioTotalInput.value = "0.00";
    precioGeneralInput.value = "0.00";
    //desgloseLabel.textContent = "0.00 + 0.00 =";
    precioTotalInput.setAttribute("disabled", "true");

  } else {
    precioTotalInput.removeAttribute("disabled");
    this.actualizarDesdePrecioGeneral();
  }
}
}
