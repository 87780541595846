import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["detallePago", "boton"];

  connect() {
    // Buscar el botón preseleccionado y activarlo
    const seleccionado = this.botonTargets.find(btn => btn.classList.contains("btn-primary"));
    if (seleccionado) {
      this.cargarMetodoPago(seleccionado);
    }
  }

  seleccionar(event) {
    const botonSeleccionado = event.currentTarget;

    // Remueve la clase "btn-primary" de todos los botones y vuelve a poner "btn-outline-primary"
    this.botonTargets.forEach((btn) => {
      btn.classList.remove("btn-primary", "text-white");
      btn.classList.add("btn-outline-primary");
    });

    // Agrega la clase "btn-primary" al botón seleccionado
    botonSeleccionado.classList.remove("btn-outline-primary");
    botonSeleccionado.classList.add("btn-primary", "text-white");

    this.cargarMetodoPago(botonSeleccionado);
  }

  cargarMetodoPago(boton) {
  const metodo = boton.dataset.pagosMetodoParam;
  const origen = boton.dataset.pagosOrigenParam || ""; // Captura el parámetro "origen", si existe
  const idKey = origen === "pedido" ? "pedido_id" : "remision_id";
  const idValue = boton.dataset.pagosRemisionIdParam;

  if (!this.hasDetallePagoTarget) {
    console.error("Elemento detallePago no encontrado");
    return;
  }

  fetch(`/remisiones/show_metodo_pago?metodo=${metodo}&${idKey}=${idValue}`)
    .then(response => response.text())
    .then(html => {
      this.detallePagoTarget.innerHTML = html;
      this.ejecutarScripts(html); // Ejecutar scripts después de inyectar HTML
    })
    .catch(error => console.error("Error cargando el parcial:", error));
}

  ejecutarScripts(html) {
    const scripts = new DOMParser().parseFromString(html, "text/html").querySelectorAll("script");
    scripts.forEach(script => {
      const newScript = document.createElement("script");
      if (script.src) {
        newScript.src = script.src;
      } else {
        newScript.textContent = script.textContent;
      }
      document.body.appendChild(newScript).remove(); // Ejecuta y remueve el script para evitar duplicados
    });
  }
}
