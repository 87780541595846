import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.modal = this.element;
    this.handleBack = this.handleBack.bind(this);
    this.handleAndroidBack = this.handleAndroidBack.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);

    window.addEventListener("popstate", this.handleBack);

    // Detectar si es un dispositivo Android y agregar el evento para el botón "Atrás"
    if (window.navigator.userAgent.toLowerCase().includes("android")) {
      document.addEventListener("backbutton", this.handleAndroidBack, false);
    }

    // Detectar cuando el modal se cierra manualmente
    this.modal.addEventListener("hidden.bs.modal", this.handleModalClose);
  }

  disconnect() {
    window.removeEventListener("popstate", this.handleBack);
    document.removeEventListener("backbutton", this.handleAndroidBack);
    this.modal.removeEventListener("hidden.bs.modal", this.handleModalClose);
  }

  pushState() {
    console.log("[modal-history] pushState ejecutado: agregando estado al historial");
    history.pushState({ modalOpen: true }, "", window.location.href);
  }

  handleBack(event) {
    console.log("[modal-history] popstate detectado");
    if (this.modal.classList.contains("show")) {
      console.log("[modal-history] Modal está abierto, cerrándolo");
      this.closeModal();
      event.preventDefault(); // Evita la navegación
    } else {
      console.log("[modal-history] Modal no está abierto, permitiendo navegación");
    }
  }

  handleAndroidBack(event) {
    console.log("[modal-history] Botón atrás en Android presionado");
    if (this.modal.classList.contains("show")) {
      console.log("[modal-history] Modal está abierto, cerrándolo");
      this.closeModal();
      event.preventDefault();
    }
  }

  handleModalClose() {
    console.log("[modal-history] Modal se cerró manualmente, limpiando historial");
    history.replaceState(null, "", window.location.href);
  }

  closeModal() {
    console.log("[modal-history] Ejecutando closeModal");
    const modalInstance = bootstrap.Modal.getInstance(this.modal);
    if (modalInstance) {
      modalInstance.hide();
    } else {
      this.modal.classList.remove("show");
      document.body.classList.remove("modal-open");
      document.querySelector(".modal-backdrop")?.remove();
    }

    console.log("[modal-history] Historial limpio después de cerrar el modal");
    history.replaceState(null, "", window.location.href);
  }
}
