import { Controller } from "@hotwired/stimulus";

// Conectar el controller a los inputs del formulario
export default class extends Controller {
  static targets = ["costo", "precioTotal"];

  connect() {
    this.calcularPrecioTotal();
  }

  calcularPrecioTotal() {
    let costo = parseFloat(this.costoTarget.value) || 0;
    let impuestos = this.calcularImpuestos(costo);
    this.precioTotalTarget.value = (costo + impuestos).toFixed(2);
  }

  calcularCosto() {
    let precioTotal = parseFloat(this.precioTotalTarget.value) || 0;
    let factorImpuesto = this.factorImpuesto();
    this.costoTarget.value = (precioTotal / factorImpuesto).toFixed(2);
  }

  calcularImpuestos(costo) {
    let totalImpuesto = 0;

    // Obtén los impuestos desde el dataset
    let impuestos = JSON.parse(this.data.get("impuestos") || "[]");

    impuestos.forEach((porcentaje) => {
      totalImpuesto += (costo * porcentaje) / 100;
    });

    return totalImpuesto;
  }

  factorImpuesto() {
    let impuestos = JSON.parse(this.data.get("impuestos") || "[]");
    let factor = 1;

    impuestos.forEach((porcentaje) => {
      factor += porcentaje / 100;
    });

    return factor;
  }
}
