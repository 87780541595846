import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["billetePaga", "cambio"];

  connect() {
  }

  calcularCambio() {
    // Obtener valores
    const totalNota = parseFloat(document.getElementById("total_nota").value) || 0;
    const billetePaga = parseFloat(this.billetePagaTarget.value) || 0;

    // Calcular cambio
    const cambio = billetePaga - totalNota;

    // Mostrar resultado en el span de cambio
    this.cambioTarget.textContent = cambio >= 0 ? `$ ${cambio.toFixed(2)}` : "$ --.--";
  }


}
