import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["btnCobrarField"];

  connect() {
  }

  capturarBoton(event) {
    //console.log("🛑 Botón presionado:", event.target.value);
    this.btnCobrarFieldTarget.value = event.target.value; // Guardar el botón presionado en el campo oculto
  }

}
