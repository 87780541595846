import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form"];

  static values = {
    url: String,
    tipoImpresora: String,
    numCopias: Number,
    ipImpresora: String,
    imagen: String,
    firstLines: String,
    lastLines: String,
    productos: String,
    numCaracteres: Number,
    marcaImpresora: String,
    lineHeight: Boolean,
    datosRem: String
  };

  connect() {
    console.log("🖨 Print controller conectado");

    // Verifica si esta impresión viene de `realizar_venta_adicional`
    if (this.element.dataset.ventaFinalizada === "true") {
      console.log("✅ Venta finalizada detectada, iniciando impresión...");
      this.ensurePrintDataIsReady().then(() => {
        this.sendTicket();
      });
    } else {
      console.log("⏳ No es una venta finalizada, no se imprimirá.");
    }
  }

  async ensurePrintDataIsReady(maxRetries = 10, delay = 200) {
    for (let i = 0; i < maxRetries; i++) {
      if (this.urlValue && this.tipoImpresoraValue && this.productosValue) {
        console.log("✅ Datos de impresión listos.");
        return;
      }
      console.warn(`⏳ Esperando datos de impresión... intento ${i + 1}/${maxRetries}`);
      await new Promise(resolve => setTimeout(resolve, delay));
    }
    console.error("❌ No se pudo obtener los datos de impresión a tiempo.");
  }

  sendTicket() {
    console.log("🖨 Enviando ticket a URL:", this.urlValue);

    if (!this.urlValue) {
      console.error("❌ No se puede enviar el ticket porque la URL está vacía.");
      return;
    }

    const payload = {
      tipo_impresora: this.tipoImpresoraValue,
      num_copias: this.numCopiasValue,
      ip_impresora: this.ipImpresoraValue,
      imagen: this.imagenValue,
      first_lines: JSON.parse(this.firstLinesValue || "[]"),
      last_lines: JSON.parse(this.lastLinesValue || "[]"),
      productos: JSON.parse(this.productosValue || "[]"),
      num_car: this.numCaracteresValue,
      m_impresora: this.marcaImpresoraValue,
      line_height: this.lineHeightValue,
      datos_rem: JSON.parse(this.datosRemValue || "[]")
    };

    fetch(this.urlValue, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`Error HTTP: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        console.log("✅ Impresión exitosa:", data);
      })
      .catch(error => {
        console.error("❌ Error al imprimir:", error);
      });
  }
  setSubmitAction(event) {
  let submitter = event.submitter || document.activeElement;
  if (!submitter) return;

  // Crear o actualizar un campo oculto con el botón presionado
  let hiddenInput = this.formTarget.querySelector('input[name="btn_cobrar"]');
  if (!hiddenInput) {
    hiddenInput = document.createElement("input");
    hiddenInput.type = "hidden";
    hiddenInput.name = "btn_cobrar";
    this.formTarget.appendChild(hiddenInput);
  }
  hiddenInput.value = submitter.value;
}

}
