import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["scrollContainer"]

  connect() {
    this.checkScroll()
    this.scrollContainerTarget.addEventListener("scroll", this.checkScroll.bind(this))
    window.addEventListener("resize", this.checkScroll.bind(this)) // Para recalcular al cambiar el tamaño de la pantalla
  }

  scrollLeft() {
    this.scrollContainerTarget.scrollBy({ left: -200, behavior: "smooth" })
  }

  scrollRight() {
    this.scrollContainerTarget.scrollBy({ left: 200, behavior: "smooth" })
  }

  checkScroll() {
    const container = this.scrollContainerTarget
    const leftButton = this.element.querySelector(".scroll-btn.left")
    const rightButton = this.element.querySelector(".scroll-btn.right")

    if (container.scrollLeft > 0) {
      leftButton.classList.remove("d-none")
    } else {
      leftButton.classList.add("d-none")
    }

    if (container.scrollLeft + container.clientWidth < container.scrollWidth) {
      rightButton.classList.remove("d-none")
    } else {
      rightButton.classList.add("d-none")
    }
  }
}
